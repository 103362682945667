import _get from 'lodash/get'

import { CONSTANTS } from '@epilogue/common'

import {
  ONTARIO_TERMS,
  provinceDictionary,
} from '../../../../common/utils/provinceDictionary'
import {
  nonHensonTrustProvinces,
  PROVINCES,
  SUPPORTED_PROVINCES,
  supportedProvinces,
  unsupportedProvinces,
} from '../../../../common/utils/provinces'
import familyDisabilityProgram from '../dynamicText/familyDisabilityProgram'
import clearCharitableGiftDetailsModal from '../interceptModals/clearCharitableGiftDetailsModal'

const section = CONSTANTS.questionnaireSectionTypes.BASICS.id

export default {
  id: section,
  description: { tKey: 'questionnaire.basics:description' },
  questions: [
    {
      id: 1570561475,
      pathname: 'province',
      section,
      altProgressText: '',
      label: { tKey: 'questionnaire.basics:province.label' },
      label2: { tKey: 'questionnaire.basics:province.label2' },
      fields: [
        {
          name: 'province',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.basics:province.fields.province.reviewLabel',
          },
          options: [
            ...supportedProvinces.map(({ value, tKey }) => ({
              value,
              label: { tKey },
            })),
            {
              value: 'other',
              label: {
                tKey: 'questionnaire.basics:province.fields.province.options.other.label',
              },
            },
          ],
        },
      ],
    },

    {
      id: 1572280935,
      pathname: 'unsupported-province',
      section,
      altProgressText: '',
      label: { tKey: 'questionnaire.basics:unsupported-province.label' },
      fields: [
        {
          name: 'unsupportedProvince',
          type: 'select',
          placeholder: {
            tKey: 'questionnaire.basics:unsupported-province.fields.unsupportedProvince.placeholder',
          },
          reviewLabel: {
            tKey: 'questionnaire.basics:unsupported-province.fields.unsupportedProvince.reviewLabel',
          },
          options: unsupportedProvinces.map(({ value, tKey }) => ({
            value,
            label: { tKey },
          })),
        },
      ],
      trigger: { province: 'other' },
    },

    {
      id: 1572281497,
      pathname: 'unsupported-province-email',
      section,
      altProgressText: '',
      type: 'unsupportedProvince',
      hideOnReview: true,
      fields: [
        {
          name: 'unsupportedProvinceEmail',
          type: 'email',
          placeholder: { tKey: 'common:emailAddress' },
        },
      ],
      trigger: {
        $and: [
          { province: 'other' },
          { unsupportedProvince: { $exists: true } },
        ],
      },
    },

    {
      id: 1571428669,
      pathname: 'partner',
      section,
      altProgressText: '',

      label: [
        { tKey: 'questionnaire.basics:partner.label.part1' },
        {
          term: { tKey: 'questionnaire.basics:partner.label.part2.term' },
          definition: {
            tKey: 'questionnaire.basics:partner.label.part2.definition',
          },
        },
        { tKey: 'questionnaire.basics:partner.label.part3' },
      ],

      sidebar: [
        {
          type: 'accordion',
          header: { tKey: 'questionnaire.basics:partner.sidebar.item1.header' },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:partner.sidebar.item1.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:partner.sidebar.item1.children.item2.body',
              },
            },
          ],
        },
      ],

      fields: [
        {
          name: 'hasPartner',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.basics:partner.fields.hasPartner.reviewLabel',
          },
          options: [
            {
              value: 'yes',
              label: {
                tKey: 'questionnaire.basics:partner.fields.hasPartner.options.yes.label',
              },
              subLabel: {
                tKey: 'questionnaire.basics:partner.fields.hasPartner.options.yes.subLabel',
              },
            },
            {
              value: 'no',
              label: {
                tKey: 'questionnaire.basics:partner.fields.hasPartner.options.no.label',
              },
              subLabel: {
                tKey: 'questionnaire.basics:partner.fields.hasPartner.options.no.subLabel',
              },
            },
          ],
        },

        /*
         * charityPartnerFamilyLegacyGiftDetails is an answerStore field that
         * gets added to a partner's answerStore when the user that is paying
         * for the partner's account leaves a Family Legacy Gift. This field's
         * value holds the details of the paying user's Family Legacy Gift. If
         * a partner has this field in their answerStore, we never want it to
         * get cleansed under any circumstances, which is why a 'preventCleanse'
         * field is located on the hasPartner question. Because we can be sure
         * that the hasPartner question will never be untriggered, we can be sure
         * that this field, once it exists, will always exist in the answerStore
         */
        {
          name: 'charityPartnerFamilyLegacyGiftDetails',
          type: 'preventCleanse',
          hideOnReview: true,
          optional: true,
        },
      ],
      interceptModal: [
        {
          ...clearCharitableGiftDetailsModal,
          trigger: {
            $and: [
              { hasPartner: 'yes' },
              { hasChildren: 'yes' },
              { charitableGiftDetails: { $elemMatch: { isPercent: true } } },
            ],
          },
        },
      ],
    },
    {
      id: 1570148292,
      pathname: 'children',
      section,
      altProgressText: '',

      label: [
        { tKey: 'questionnaire.basics:children.label.part1' },
        {
          term: { tKey: 'questionnaire.basics:children.label.part2.term' },
          definition: {
            tKey: 'questionnaire.basics:children.label.part2.definition',
          },
        },
        { tKey: 'questionnaire.basics:children.label.part3' },
      ],

      sidebar: [
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.basics:children.sidebar.item1.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:children.sidebar.item1.children.item1.body',
              },
            },
          ],
        },
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.basics:children.sidebar.item2.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:children.sidebar.item2.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:children.sidebar.item2.children.item2.body',
              },
            },
          ],
        },
      ],

      fields: [
        {
          name: 'hasChildren',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.basics:children.fields.hasChildren.reviewLabel',
          },
          options: [
            {
              value: 'yes',
              label: {
                tKey: 'questionnaire.basics:children.fields.hasChildren.options.yes.label',
              },
            },
            {
              value: 'no',
              label: {
                tKey: 'questionnaire.basics:children.fields.hasChildren.options.no.label',
              },
            },
          ],
        },
      ],
      interceptModal: [
        {
          ...clearCharitableGiftDetailsModal,
          trigger: {
            $and: [
              { hasChildren: 'no' },
              { charitableGiftDetails: { $elemMatch: { isPercent: true } } },
            ],
          },
        },
        {
          ...clearCharitableGiftDetailsModal,
          primaryButtonAction: {
            type: CONSTANTS.interceptActionTypes.NEXT_QUESTION,
            questionFragments: { charityPartnerGiftAmount: undefined },
          },
          trigger: {
            hasChildren: 'yes',
            charityPartnerGiftType: '%',
            $or: [
              { charityPartnerGiftAmount: '100' },
              { charityPartnerGiftAmount: '100.0' },
              { charityPartnerGiftAmount: '100.00' },
            ],
          },
        },
      ],
    },

    {
      id: 1576514009,
      pathname: 'children-other-relationship',
      section,
      altProgressText: '',
      label: { tKey: 'questionnaire.basics:children-other-relationship.label' },
      sidebar: [
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.basics:children-other-relationship.sidebar.item1.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:children-other-relationship.sidebar.item1.children.item1.body',
              },
            },
          ],
        },
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.basics:children-other-relationship.sidebar.item2.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:children-other-relationship.sidebar.item2.children.item1.body',
              },
            },
          ],
        },
      ],
      fields: [
        {
          name: 'childrenOtherRelationship',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.basics:children-other-relationship.fields.childrenOtherRelationship.reviewLabel',
          },
          options: [
            {
              value: 'yes',
              label: {
                tKey: 'questionnaire.basics:children-other-relationship.fields.childrenOtherRelationship.options.yes.label',
              },
            },
            {
              value: 'no',
              label: {
                tKey: 'questionnaire.basics:children-other-relationship.fields.childrenOtherRelationship.options.no.label',
              },
            },
          ],
        },
      ],
      trigger: { $and: [{ hasPartner: 'yes', hasChildren: 'yes' }] },
    },

    {
      id: 1576514017,
      pathname: 'spousal-trust-lawyer',
      section,
      type: 'dropOff',
      hideOnReview: true,
      altProgressText: '',
      dropOffType: 'spousalTrust',
      dropOffLabel: {
        tKey: 'questionnaire.basics:spousal-trust-lawyer.dropOffLabel',
      },
      paragraphs: [],
      fields: [
        {
          name: 'spousalTrustEmail',
          type: 'text',
        },
      ],
      trigger: {
        $and: [
          {
            $or: [
              { isPartner: { $exists: false } },
              {
                $and: [{ isPartner: { $exists: true } }, { isPartner: false }],
              },
            ],
          },
          { hasPartner: 'yes', hasChildren: 'yes' },
          { childrenOtherRelationship: 'yes' },
        ],
      },
    },

    {
      id: 1641571285,
      pathname: 'spousal-trust-partner',
      type: 'partnerDropOff',
      altProgressText: '',
      hideOnReview: true,
      section,
      fields: [{ name: 'spousalTrustPartner', type: 'text' }],
      trigger: {
        $and: [
          { isPartner: true },
          { hasPartner: 'yes', hasChildren: 'yes' },
          { childrenOtherRelationship: 'yes' },
        ],
      },
    },
    {
      id: 1590092745,
      pathname: 'family-disability',
      section,
      altProgressText: '',

      sidebar: [
        {
          type: 'accordion',
          header: ({ answerStore: { province }, languageType }) => ({
            tKey: 'questionnaire.basics:family-disability.sidebar.item1.header',
            options: {
              familyDisabilityProgram: familyDisabilityProgram({
                province,
                languageType,
              }),
            },
          }),
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:family-disability.sidebar.item1.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:family-disability.sidebar.item1.children.item2.body',
              },
            },
          ],
        },
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.basics:family-disability.sidebar.item2.header',
          },
          children: [
            {
              type: 'text',
              body: ({ answerStore: { province }, languageType }) => ({
                tKey: 'questionnaire.basics:family-disability.sidebar.item2.children.item1.body',
                options: {
                  familyDisabilityProgram: familyDisabilityProgram({
                    province,
                    languageType,
                  }),
                },
              }),
            },
          ],
        },
      ],

      label: ({ answerStore: { province }, languageType }) => ({
        tKey: 'questionnaire.basics:family-disability.label',
        options: {
          familyDisabilityProgram: familyDisabilityProgram({
            province,
            languageType,
          }),
        },
      }),

      fields: [
        {
          name: 'familyDisability',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.basics:family-disability.fields.familyDisability.reviewLabel',
          },
          clearAfterProvinceChange: true,
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      trigger: {
        $and: [
          {
            $or: [{ hasPartner: 'yes' }, { hasChildren: 'yes' }],
            province: { $exists: true, $nin: nonHensonTrustProvinces },
          },
        ],
      },
    },

    {
      id: 1590092752,
      pathname: 'family-disability-lawyer',
      section,
      type: 'dropOff',
      hideOnReview: true,
      altProgressText: '',
      dropOffType: 'familyDisability',
      dropOffLabel: {
        tKey: 'questionnaire.basics:family-disability-lawyer.dropOffLabel',
      },
      paragraphs: [],
      fields: [{ name: 'familyDisabilityEmail', type: 'text' }],
      trigger: {
        $and: [
          {
            familyDisability: 'yes',
            province: { $exists: true },
            $or: [{ hasPartner: 'yes' }, { hasChildren: 'yes' }],
          },
        ],
      },
    },

    {
      id: 1610120143,
      pathname: 'family-will',
      section,
      type: 'familyWill',
      altProgressText: '',
      hideOnReview: true,
      hideFromPartner: true,
      hideAfterAccount: true,
      fields: [{ type: 'text', name: 'acceptsFamilyWill', optional: true }],
      trigger: {
        $and: [{ hasChildren: 'yes' }, { hasPartner: { $exists: true } }],
      },
    },

    {
      id: 1610119345,
      pathname: 'not-right-for-me',
      section,
      type: 'dropOff',
      hideOnReview: true,
      hideAfterAccount: true,
      altProgressText: '',
      dropOffType: 'notRightForMe',
      dropOffLabel: {
        tKey: 'questionnaire.basics:not-right-for-me.dropOffLabel',
      },
      paragraphs: [],
      fields: [{ name: 'notRightForMe', type: 'text' }],
      trigger: { acceptsFamilyWill: 'no' },
    },

    {
      id: 1570816383,
      pathname: 'create-account',
      type: 'createAccount',
      section,
      altProgressText: '',
      hideAfterAccount: true,
      hideFromPartner: true,
      hideOnReview: true,
      sidebar: [
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.basics:create-account.sidebar.item1.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:create-account.sidebar.item1.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:create-account.sidebar.item1.children.item2.body',
              },
            },
          ],
        },
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.basics:create-account.sidebar.item2.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:create-account.sidebar.item2.children.item1.body',
              },
            },
          ],
        },
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.basics:create-account.sidebar.item3.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:create-account.sidebar.item3.children.item1.body',
              },
            },
          ],
        },
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.basics:create-account.sidebar.item4.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:create-account.sidebar.item4.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:create-account.sidebar.item4.children.item2.body',
              },
            },
          ],
        },
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.basics:create-account.sidebar.item5.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:create-account.sidebar.item5.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:create-account.sidebar.item5.children.item2.body',
              },
            },
          ],
        },
      ],
      autoOpenSidebar: true,
      fields: [
        {
          name: 'email',
          type: 'email',
          placeholder: { tKey: 'common:emailAddress' },
        },
        {
          name: 'password',
          type: 'password',
          placeholder: { tKey: 'common:password' },
        },
      ],
    },

    {
      id: 1585055208,
      pathname: 'account-created',
      type: 'accountCreated',
      section,
      altProgressText: '',
      // hideAfterAccount: true,
      hideFromPartner: true,
      autoCloseSidebar: true,
    },

    {
      id: 1610053511,
      pathname: 'product',
      section,
      altProgressText: '',
      label: { tKey: 'questionnaire.basics:product.label' },
      label2: { tKey: 'questionnaire.basics:product.label2' },
      hideFromPartner: true,
      hideAfterPayment: true,
      sidebar: [
        {
          type: 'accordion',
          header: { tKey: 'questionnaire.basics:product.sidebar.item1.header' },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:product.sidebar.item1.children.item1.body',
              },
            },
          ],
        },
        {
          type: 'accordion',
          header: { tKey: 'questionnaire.basics:product.sidebar.item2.header' },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:product.sidebar.item2.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:product.sidebar.item2.children.item2.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:product.sidebar.item2.children.item3.body',
              },
            },
          ],
        },
      ],
      fields: [
        {
          name: 'product',
          type: 'quickRadio',
          reviewLabel: 'Package',
          options: [
            {
              value: 'willPoa',
              label: {
                tKey: 'questionnaire.basics:product.fields.product.options.willPoa.label',
              },
              reviewLabel: {
                tKey: 'questionnaire.basics:product.fields.product.options.willPoa.reviewLabel',
              },
              pill: { tKey: 'common:mostCommon' },
            },
            {
              value: 'will',
              label: {
                tKey: 'questionnaire.basics:product.fields.product.options.will.label',
              },
              reviewLabel: {
                tKey: 'questionnaire.basics:product.fields.product.options.will.reviewLabel',
              },
            },
          ],
        },
      ],
      trigger: {
        /*
         * isPartner is not an answerStore property, but gets added later
         * to the test object in the questionnaire engine.
         * Be careful when using isPartner in a trigger. Using isPartner in
         * a trigger doesn't affect whether or not the fields of this question
         * will be cleansed or not. It simply determines whether or not to display
         * the question to a partner. For answerStore cleansing purposes only, assume
         * that isPartner is always false.
         */
        $or: [
          { isPartner: { $exists: false } },
          {
            $and: [{ isPartner: { $exists: true } }, { isPartner: false }],
          },
        ],
      },
      interceptModal: [
        {
          label: ({ answerStore }) => {
            const { province } = answerStore
            return province === CONSTANTS.provinces.NEW_BRUNSWICK
              ? {
                  tKey: 'questionnaire.basics:product.interceptModal.item1.label.case1',
                }
              : {
                  tKey: 'questionnaire.basics:product.interceptModal.item1.label.case2',
                }
          },
          subLabel: ({ answerStore }) => {
            const { province } = answerStore
            return province === CONSTANTS.provinces.NEW_BRUNSWICK
              ? {
                  tKey: 'questionnaire.basics:product.interceptModal.item1.subLabel.case1',
                }
              : {
                  tKey: 'questionnaire.basics:product.interceptModal.item1.subLabel.case2',
                }
          },
          primaryButtonIcon: true,
          primaryButtonLabel: { tKey: 'common:iUnderstand' },
          primaryButtonAction: {
            type: CONSTANTS.interceptActionTypes.NEXT_QUESTION,
          },
          secondaryButtonLabel: {
            tKey: 'questionnaire.basics:product.interceptModal.item1.secondaryButtonLabel',
          },
          secondaryButtonAction: {
            type: CONSTANTS.interceptActionTypes.NEXT_QUESTION,
            questionFragments: { product: 'will' },
          },
          trigger: {
            $and: [
              { province: { $exists: true } },
              { product: 'willPoa' },
              {
                $or: [
                  { province: CONSTANTS.provinces.NEW_BRUNSWICK },
                  { province: CONSTANTS.provinces.MANITOBA },
                ],
              },
            ],
          },
        },
      ],
    },

    {
      id: 1609184317,
      pathname: 'partner-product',
      type: 'info',
      section,
      altProgressText: '',
      label: ({
        answerStore: { partnerFirstName, partnerLastName, province, product },
      }) => ({
        tKey: 'questionnaire.basics:partner-product.label',
        options: {
          partnerFirstName,
          partnerLastName,
          provinceWillPoa:
            product === 'willPoa'
              ? provinceDictionary(
                  ONTARIO_TERMS._AND_POWERS_OF_ATTORNEY,
                  province,
                )
              : '',
        },
      }),
      label2: ({ answerStore: { product } }) =>
        product === 'will'
          ? { tKey: 'questionnaire.basics:partner-product.label2.case1' }
          : { tKey: 'questionnaire.basics:partner-product.label2.case2' },
      /*
       * This field will not actually be displayed because it's an 'info'
       * question. The field is here so that the question doesn't get
       * cleansed because we need the 'product' field in the answerStore
       * to build documents.
       */
      fields: [
        {
          name: 'product',
          type: 'radio',
          reviewLabel: 'Package',
          options: [
            { value: 'will', label: 'Will Only' },
            { value: 'willPoa', label: 'Will + Incapacity Documents' },
          ],
        },
      ],
      trigger: {
        $and: [
          { partnerFirstName: { $exists: true } },
          { partnerLastName: { $exists: true } },
          /*
           * Triggered on product being will or willPoa because a partner doesn't
           * select thier own product -  it is selected for them by the initial user,
           * so their product should already be in place.
           */
          { $or: [{ product: 'will' }, { product: 'willPoa' }] },
          /*
           * isPartner is not an answerStore property, but gets added later
           * to the test object in the questionnaire engine.
           * Be careful when using isPartner in a trigger. Using isPartner in
           * a trigger doesn't affect whether or not the fields of this question
           * will be cleansed or not. It simply determines whether or not to display
           * the question to a partner. For answerStore cleansing purposes only, assume
           * that isPartner is always false.
           */
          { isPartner: true },
        ],
      },
    },

    {
      id: 1572288463,
      pathname: 'intro',
      type: 'intro',
      section,
      altProgressText: '',
      highlightPillText: {
        tKey: 'questionnaire.basics:intro.highlightPillText',
      },
      label: { tKey: 'questionnaire.basics:intro.label' },
      subLabel: { tKey: 'questionnaire.basics:intro.subLabel' },
    },

    {
      id: 1568476677,
      pathname: 'name',
      type: 'name',
      section,
      label: { tKey: 'questionnaire.basics:name.label' },
      sidebar: [
        {
          type: 'accordion',
          header: { tKey: 'questionnaire.basics:name.sidebar.item1.header' },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:name.sidebar.item1.children.item1.body',
              },
            },
          ],
        },
        {
          type: 'accordion',
          header: { tKey: 'questionnaire.basics:name.sidebar.item2.header' },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:name.sidebar.item2.children.item1.body',
              },
            },
          ],
        },
      ],
      fields: [
        {
          name: 'firstName',
          type: 'text',
          placeholder: {
            tKey: 'questionnaire.basics:name.fields.firstName.placeholder',
          },
          reviewLabel: {
            tKey: 'questionnaire.basics:name.fields.firstName.reviewLabel',
          },
        },
        {
          name: 'middleName',
          type: 'text',
          placeholder: {
            tKey: 'questionnaire.basics:name.fields.middleName.placeholder',
          },
          reviewLabel: {
            tKey: 'questionnaire.basics:name.fields.middleName.reviewLabel',
          },
          hideOnReviewIfEmpty: true,
          optional: true,
        },
        {
          name: 'lastName',
          type: 'text',
          placeholder: {
            tKey: 'questionnaire.basics:name.fields.lastName.placeholder',
          },
          reviewLabel: {
            tKey: 'questionnaire.basics:name.fields.lastName.reviewLabel',
          },
        },
        {
          name: 'commonlyKnownAsName',
          type: 'text',
          placeholder: {
            tKey: 'questionnaire.basics:name.fields.commonlyKnownAsName.placeholder',
          },
          reviewLabel: {
            tKey: 'questionnaire.basics:name.fields.commonlyKnownAsName.reviewLabel',
          },
          optional: true,
          hideOnReviewIfEmpty: true,
        },
        {
          name: 'hasCommonName',
          type: 'checkbox',
          hideOnReview: true,
          /*
           * optional must always remain true (below).
           * This field was added on October 1, 2021 and if it
           * is changed to optional: false, all legacy users (users
           * who have answered the 'name' question prior to Oct 1, 2021) will
           * log back into their account and see an incomplete questionnaire
           * because of this field. It's safe to set and leave as optional: true.
           * Do NOT change the value unless you understand the consequences.
           */
          optional: true,
        },
      ],
    },

    {
      id: 1575907342,
      pathname: 'has-partner-specific',
      section,
      label: { tKey: 'questionnaire.basics:has-partner-specific.label' },
      fields: [
        {
          name: 'hasPartnerSpecific',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.basics:has-partner-specific.fields.hasPartnerSpecific.reviewLabel',
          },
          options: [
            {
              value: 'married',
              label: {
                tKey: 'questionnaire.basics:has-partner-specific.fields.hasPartnerSpecific.options.married.label',
              },
              reviewLabel: {
                tKey: 'questionnaire.basics:has-partner-specific.fields.hasPartnerSpecific.options.married.reviewLabel',
              },
            },
            {
              value: 'commonLaw',
              label: {
                tKey: 'questionnaire.basics:has-partner-specific.fields.hasPartnerSpecific.options.commonLaw.label',
              },
              reviewLabel: {
                tKey: 'questionnaire.basics:has-partner-specific.fields.hasPartnerSpecific.options.commonLaw.reviewLabel',
              },
            },
            {
              value: 'engaged',
              label: {
                tKey: 'questionnaire.basics:has-partner-specific.fields.hasPartnerSpecific.options.engaged.label',
              },
              reviewLabel: {
                tKey: 'questionnaire.basics:has-partner-specific.fields.hasPartnerSpecific.options.engaged.reviewLabel',
              },
            },
            {
              value: 'other',
              label: {
                tKey: 'questionnaire.basics:has-partner-specific.fields.hasPartnerSpecific.options.other.label',
              },
              reviewLabel: {
                tKey: 'questionnaire.basics:has-partner-specific.fields.hasPartnerSpecific.options.other.reviewLabel',
              },
            },
          ],
        },
      ],
      interceptModal: [
        {
          label: {
            tKey: 'questionnaire.basics:has-partner-specific.interceptModal.item1.label',
          },
          subLabel: {
            tKey: 'questionnaire.basics:has-partner-specific.interceptModal.item1.subLabel',
          },
          primaryButtonIcon: true,
          primaryButtonLabel: {
            tKey: 'questionnaire.basics:has-partner-specific.interceptModal.item1.primaryButtonLabel',
          },
          primaryButtonAction: {
            type: CONSTANTS.interceptActionTypes.NEXT_QUESTION,
          },
          trigger: {
            province: CONSTANTS.provinces.PRINCE_EDWARD_ISLAND,
            hasPartnerSpecific: 'engaged',
          },
        },
      ],
      trigger: { hasPartner: 'yes' },
    },

    {
      id: 1634674948,
      pathname: 'contemplation-of-marriage',
      section,
      label: { tKey: 'questionnaire.basics:contemplation-of-marriage.label' },
      label2: { tKey: 'questionnaire.basics:contemplation-of-marriage.label2' },
      subLabel: {
        tKey: 'questionnaire.basics:contemplation-of-marriage.subLabel',
      },
      fields: [
        {
          name: 'inContemplationOfMarriage',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.basics:contemplation-of-marriage.fields.inContemplationOfMarriage.reviewLabel',
          },
          options: [
            {
              value: 'yes',
              label: {
                tKey: 'questionnaire.basics:contemplation-of-marriage.fields.inContemplationOfMarriage.options.yes.label',
              },
              pill: { tKey: 'common:mostCommon' },
            },
            {
              value: 'no',
              label: {
                tKey: 'questionnaire.basics:contemplation-of-marriage.fields.inContemplationOfMarriage.options.no.label',
              },
            },
          ],
        },
      ],
      trigger: {
        hasPartnerSpecific: 'engaged',
        $or: [
          { province: CONSTANTS.provinces.MANITOBA },
          { province: CONSTANTS.provinces.NEW_BRUNSWICK },
          { province: CONSTANTS.provinces.NEWFOUNDLAND_AND_LABRADOR },
          { province: CONSTANTS.provinces.NOVA_SCOTIA },
        ],
      },
    },

    {
      id: 1575907514,
      pathname: 'no-partner-specific',
      section,
      label: { tKey: 'questionnaire.basics:no-partner-specific.label' },
      fields: [
        {
          name: 'noPartnerSpecific',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.basics:no-partner-specific.fields.noPartnerSpecific.reviewLabel',
          },
          options: [
            {
              value: 'single',
              label: {
                tKey: 'questionnaire.basics:no-partner-specific.fields.noPartnerSpecific.options.single.label',
              },
              reviewLabel: {
                tKey: 'questionnaire.basics:no-partner-specific.fields.noPartnerSpecific.options.single.reviewLabel',
              },
            },
            {
              value: 'separated',
              label: {
                tKey: 'questionnaire.basics:no-partner-specific.fields.noPartnerSpecific.options.separated.label',
              },
              reviewLabel: {
                tKey: 'questionnaire.basics:no-partner-specific.fields.noPartnerSpecific.options.separated.reviewLabel',
              },
            },
            {
              value: 'divorced',
              label: {
                tKey: 'questionnaire.basics:no-partner-specific.fields.noPartnerSpecific.options.divorced.label',
              },
              reviewLabel: {
                tKey: 'questionnaire.basics:no-partner-specific.fields.noPartnerSpecific.options.divorced.reviewLabel',
              },
            },
            {
              value: 'widowed',
              label: {
                tKey: 'questionnaire.basics:no-partner-specific.fields.noPartnerSpecific.options.widowed.label',
              },
              reviewLabel: {
                tKey: 'questionnaire.basics:no-partner-specific.fields.noPartnerSpecific.options.widowed.reviewLabel',
              },
            },
          ],
        },
      ],
      trigger: { hasPartner: 'no' },
    },

    {
      id: 1572288467,
      pathname: 'date-of-birth',
      type: 'dateOfBirth',
      section,
      label: { tKey: 'questionnaire.basics:date-of-birth.label' },
      fields: [
        {
          name: 'dateOfBirth',
          reviewLabel: {
            tKey: 'questionnaire.basics:date-of-birth.fields.dateOfBirth.reviewLabel',
          },
          clearAfterProvinceChange: true,
          /*
           * Don't need type of 'date', which is usually used
           * solely for validation purposes, because we are going to
           * be using the question type, 'dateOfBirth' for validation.
           * type of 'text' works fine in this instance
           */
          type: 'text',
        },
      ],
      trigger: {
        $or: SUPPORTED_PROVINCES.map((p) => ({ province: p })),
      },
    },

    {
      id: 1573839035,
      pathname: 'city-town',
      section,

      label: ({ answerStore: { province } }) => {
        const translatedProvince = _get(
          Object.values(PROVINCES).find(({ value }) => value === province),
          'tKey',
          province,
        )

        return {
          tKey: 'questionnaire.basics:city-town.label',
          options: { province: translatedProvince },
        }
      },

      fields: [
        {
          name: 'cityTown',
          type: 'text',
          optional: false,
          placeholder: {
            tKey: 'questionnaire.basics:city-town.fields.cityTown.placeholder',
          },
          reviewLabel: {
            tKey: 'questionnaire.basics:city-town.fields.cityTown.reviewLabel',
          },
        },
      ],
      trigger: { province: { $exists: true } },
    },

    {
      id: 1572288804,
      pathname: 'gender',
      section,
      label: { tKey: 'questionnaire.basics:gender.label' },
      sidebar: [
        {
          type: 'accordion',
          header: { tKey: 'questionnaire.basics:gender.sidebar.item1.header' },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:gender.sidebar.item1.children.item1.body',
              },
            },
          ],
        },
      ],
      fields: [
        {
          name: 'gender',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.basics:gender.fields.gender.reviewLabel',
          },
          options: [
            {
              value: 'male',
              label: {
                tKey: 'questionnaire.basics:gender.fields.gender.options.male.label',
              },
              reviewLabel: {
                tKey: 'questionnaire.basics:gender.fields.gender.options.male.reviewLabel',
              },
            },
            {
              value: 'female',
              label: {
                tKey: 'questionnaire.basics:gender.fields.gender.options.female.label',
              },
              reviewLabel: {
                tKey: 'questionnaire.basics:gender.fields.gender.options.female.reviewLabel',
              },
            },
            {
              value: 'other',
              label: {
                tKey: 'questionnaire.basics:gender.fields.gender.options.other.label',
              },
              reviewLabel: {
                tKey: 'questionnaire.basics:gender.fields.gender.options.other.reviewLabel',
              },
            },
            {
              value: 'notSay',
              label: {
                tKey: 'questionnaire.basics:gender.fields.gender.options.notSay.label',
              },
              reviewLabel: {
                tKey: 'questionnaire.basics:gender.fields.gender.options.notSay.reviewLabel',
              },
            },
          ],
        },
      ],
    },

    {
      id: 1571076852,
      pathname: 'pets',
      section,
      label: { tKey: 'questionnaire.basics:pets.label' },
      subLabel: { tKey: 'questionnaire.basics:pets.subLabel' },
      fields: [
        {
          name: 'hasPets',
          type: 'quickRadio',
          reviewLabel: { tKey: 'questionnaire.basics:pets.reviewLabel' },
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            {
              value: 'no',
              label: { tKey: 'common:no' },
            },
          ],
        },
      ],
    },

    {
      id: 1608147076,
      pathname: 'resp',
      section,
      label: ({ answerStore: { hasChildren } }) =>
        hasChildren === 'yes'
          ? { tKey: 'questionnaire.basics:resp.label.case1' }
          : { tKey: 'questionnaire.basics:resp.label.case2' },
      subLabel: { tKey: 'questionnaire.basics:resp.subLabel' },
      fields: [
        {
          name: 'hasResps',
          type: 'quickRadio',
          reviewLabel: {
            tKey: 'questionnaire.basics:resp.fields.hasResps.reviewLabel',
          },
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
    },

    {
      id: 1659977426,
      pathname: 'investment-accounts',
      type: 'investmentAccounts',
      section,
      label: { tKey: 'questionnaire.basics:investment-accounts.label' },
      subLabel: { tKey: 'questionnaire.basics:investment-accounts.subLabel' },
      sidebar: [
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.basics:investment-accounts.sidebar.item1.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:investment-accounts.sidebar.item1.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:investment-accounts.sidebar.item1.children.item2.body',
              },
            },
          ],
        },
        {
          type: 'accordion',
          trigger: { 'meta.age': { $lt: 71 } },
          header: {
            tKey: 'questionnaire.basics:investment-accounts.sidebar.item2.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:investment-accounts.sidebar.item2.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:investment-accounts.sidebar.item2.children.item2.body',
              },
            },
            {
              type: 'youtube',
              youtubeId: 'LjzP7YWoMW0',
              // TAG: TD setup
              trigger: { 'referrer.ref': { $ne: 'td' } },
              caption: {
                tKey: 'questionnaire.basics:investment-accounts.sidebar.item2.children.item3.caption',
              },
            },
          ],
        },
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.basics:investment-accounts.sidebar.item3.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:investment-accounts.sidebar.item3.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:investment-accounts.sidebar.item3.children.item2.body',
              },
            },
          ],
        },
      ],
      fields: [
        {
          name: 'investmentAccounts',
          type: 'multiSelect',
          preventAnswerStoreUpdatedSinceGenerate: true,
          /*
           * Options array should remain empty because the field's
           * options are handled in the InvestmentAccounts.js file
           */
          options: [],
          reviewLabel: {
            tKey: 'questionnaire.basics:investment-accounts.fields.investmentAccounts.reviewLabel',
          },
        },
      ],
      /*
       * The 'investmentAccounts' custom question uses the user's date of birth, so
       * do NOT remove the `dateOfBirth: { $exists:true }` trigger below
       */
      trigger: { dateOfBirth: { $exists: true } },
    },

    {
      id: 1668785137,
      pathname: 'life-insurance',
      section,
      label: { tKey: 'questionnaire.basics:life-insurance.label' },
      subLabel: { tKey: 'questionnaire.basics:life-insurance.subLabel' },
      fields: [
        {
          name: 'hasLifeInsurance',
          type: 'quickRadio',
          preventAnswerStoreUpdatedSinceGenerate: true,
          reviewLabel: {
            tKey: 'questionnaire.basics:life-insurance.fields.hasLifeInsurance.reviewLabel',
          },
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
            { value: 'unsure', label: { tKey: 'common:notSure' } },
          ],
        },
      ],
      sidebar: [
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.basics:life-insurance.sidebar.item1.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:life-insurance.sidebar.item1.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:life-insurance.sidebar.item1.children.item2.body',
              },
            },
          ],
        },
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.basics:life-insurance.sidebar.item2.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:life-insurance.sidebar.item2.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:life-insurance.sidebar.item2.children.item2.body',
              },
            },
          ],
        },
      ],
    },

    {
      id: 1667835226,
      pathname: 'canadian-real-estate',
      section,
      label: { tKey: 'questionnaire.basics:canadian-real-estate.label' },
      subLabel: { tKey: 'questionnaire.basics:canadian-real-estate.subLabel' },
      fields: [
        {
          name: 'canadianRealEstate',
          type: 'quickRadio',
          preventAnswerStoreUpdatedSinceGenerate: true,
          reviewLabel: {
            tKey: 'questionnaire.basics:canadian-real-estate.fields.canadianRealEstate.reviewLabel',
          },
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
      ],
      sidebar: [
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.basics:canadian-real-estate.sidebar.item1.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:canadian-real-estate.sidebar.item1.children.item1.body',
              },
            },
          ],
        },
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.basics:canadian-real-estate.sidebar.item2.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:canadian-real-estate.sidebar.item2.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:canadian-real-estate.sidebar.item2.children.item2.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:canadian-real-estate.sidebar.item2.children.item3.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:canadian-real-estate.sidebar.item2.children.item4.body',
              },
            },
          ],
        },
        {
          type: 'accordion',
          header: {
            tKey: 'questionnaire.basics:canadian-real-estate.sidebar.item3.header',
          },
          children: [
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:canadian-real-estate.sidebar.item3.children.item1.body',
              },
            },
            {
              type: 'text',
              body: {
                tKey: 'questionnaire.basics:canadian-real-estate.sidebar.item3.children.item2.body',
              },
            },
          ],
        },
      ],
    },

    {
      id: 1635444210,
      pathname: 'foreign-property',
      section,
      label: { tKey: 'questionnaire.basics:foreign-property.label' },
      fields: [
        {
          name: 'hasForeignProperty',
          type: 'quickRadio',
          preventAnswerStoreUpdatedSinceGenerate: true,
          reviewLabel: {
            tKey: 'questionnaire.basics:foreign-property.fields.hasForeignProperty.reviewLabel',
          },
          options: [
            { value: 'yes', label: { tKey: 'common:yes' } },
            { value: 'no', label: { tKey: 'common:no' } },
          ],
        },
        {
          name: 'foreignPropertyLawyer',
          preventAnswerStoreUpdatedSinceGenerate: true,
          type: 'preventCleanse',
          hideOnReview: true,
          optional: true,
        },
      ],
      interceptModal: [
        {
          label: ({ answerStore: { province } }) => {
            const translatedProvince = _get(
              Object.values(PROVINCES).find(({ value }) => value === province),
              'tKey',
              province,
            )

            return {
              tKey: 'questionnaire.basics:foreign-property.interceptModal.label',
              options: { province: translatedProvince },
            }
          },
          subLabel: {
            tKey: 'questionnaire.basics:foreign-property.interceptModal.subLabel',
          },
          primaryButtonLabel: {
            tKey: 'questionnaire.basics:foreign-property.interceptModal.primaryButtonLabel',
          },
          primaryButtonIcon: true,
          primaryButtonAction: {
            type: CONSTANTS.interceptActionTypes.NEXT_QUESTION,
            questionFragments: { foreignPropertyLawyer: false },
          },
          secondaryButtonLabel: {
            tKey: 'questionnaire.basics:foreign-property.interceptModal.secondaryButtonLabel',
          },
          secondaryButtonAction: {
            type: CONSTANTS.interceptActionTypes.NEXT_QUESTION,
            questionFragments: { foreignPropertyLawyer: true },
          },
          trigger: { hasForeignProperty: 'yes', province: { $exists: true } },
        },
      ],
    },

    {
      id: 1635444216,
      pathname: 'foreign-property-lawyer',
      section,
      type: 'dropOff',
      hideOnReview: true,
      optional: true,
      altProgressText: '',
      dropOffType: 'foreignProperty',
      dropOffLabel: {
        tKey: 'questionnaire.basics:foreign-property-lawyer.dropOffLabel',
      },
      paragraphs: [],
      fields: [],
      trigger: { hasForeignProperty: 'yes', foreignPropertyLawyer: true },
    },

    {
      id: 1571428857,
      pathname: 'review',
      type: 'sectionReview',
      section,
      label: { tKey: 'questionnaire.basics:review.label' },
      subLabel: { tKey: 'questionnaire.basics:review.subLabel' },
    },
  ],
}
