import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { Box } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { useTranslation } from 'react-i18next'

import NumberInput from '../../../../../common/components/atoms/NumberInput'
import Text from '../../../../../common/components/atoms/Text'
import commonSelectors from '../../../../../common/ducks/commonSelectors'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../common/styles/muiTheme'
import i18n from '../../../../../i18n'
import ValidationMessage from '../../molecules/ValidationMessage/ValidationMessage'

const CharityPartnerGiftAmount = ({
  question,
  answerCache,
  charityPartner,
  displayValidation,
  handleFieldFragmentUpdate,
}) => {
  const [charityPartnerGiftAmountField, charityPartnerGiftDetailsField] =
    question.fields
  const isPercent = answerCache?.charityPartnerGiftType === '%'
  const [amount, setAmount] = useState(
    answerCache[charityPartnerGiftAmountField.name] || undefined,
  )

  const { name, number, location, isPublicFoundation } = charityPartner
  const charityPartnerGiftDetailsObj = {
    name,
    number,
    location,
    isPercent,
    type: 'charity',
    id: Date.now().toString(),
    relationship: 'charitableOrg',
    isPublicFoundation: !!isPublicFoundation,
  }

  const handleChange = (value) => {
    setAmount(value)
    handleFieldFragmentUpdate({
      [charityPartnerGiftAmountField.name]: value,
      [charityPartnerGiftDetailsField.name]: {
        ...charityPartnerGiftDetailsObj,
        amount: value,
      },
    })
  }

  const { t } = useTranslation()

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        maxWidth: '17rem',
        position: 'relative',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ position: 'absolute', top: '-1.3rem' }}>
        <ValidationMessage
          field={charityPartnerGiftAmountField}
          message={t('validation:validation.greaterThanZero')}
        />
      </Box>

      <Box
        sx={{
          width: isPercent ? '4.5rem' : '100%',
          top: isPercent ? '0.4rem' : undefined,
          position: isPercent ? 'relative' : undefined,
        }}
      >
        <NumberInput
          value={amount}
          validationMessage=""
          onInputChange={handleChange}
          name="charityPartnerGiftAmount"
          /*
           * If percent, no max length
           * If currency and language is FR_CA, max length is 10
           * Otherwise, max length is 9
           */
          maxLength={
            isPercent
              ? undefined
              : i18n.language === CONSTANTS.languageTypes.FR_CA.id
                ? 10
                : 9
          }
          isInvalid={displayValidation}
          format={isPercent ? 'percent' : 'currency'}
          placeholder={isPercent ? undefined : t('common:donationAmount')}
        />
      </Box>

      {isPercent && (
        <Text
          color={displayValidation ? themeColorTypes.RED : themeColorTypes.GREY}
          colorVariant={
            displayValidation
              ? themeColorVariants.MAIN
              : themeColorVariants.DARK
          }
          sx={{
            ml: '0.5rem',
            display: 'flex',
            alignItems: 'flex-end',
            minWidth: 'fit-content',
          }}
        >
          {t('common:percentageOfEstate')}
        </Text>
      )}
    </Box>
  )
}

CharityPartnerGiftAmount.propTypes = {
  charityPartner: PropTypes.shape({
    name: PropTypes.string,
    number: PropTypes.string,
    location: PropTypes.string,
    isPublicFoundation: PropTypes.bool,
  }).isRequired,
  displayValidation: PropTypes.bool.isRequired,
  handleFieldFragmentUpdate: PropTypes.func.isRequired,
  question: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  answerCache: PropTypes.shape({
    charityPartnerGiftType: PropTypes.oneOf(['$', '%']),
  }).isRequired,
}

const mapStateToProps = (state) => ({
  charityPartner: commonSelectors.getCharityPartner(state),
})

export default connect(mapStateToProps)(CharityPartnerGiftAmount)
