import { CONSTANTS } from '@epilogue/common'

export default {
  label: {
    tKey: 'interceptModals:clearCharitableGiftDetailsModal.label',
  },
  primaryButtonLabel: {
    tKey: 'common:saveAndContinue',
  },
  primaryButtonIcon: true,
  primaryButtonAction: {
    type: CONSTANTS.interceptActionTypes.NEXT_QUESTION,
    questionFragments: { charitableGiftDetails: undefined },
  },
  secondaryButtonLabel: {
    tKey: 'common:cancel',
  },
  secondaryButtonAction: { type: CONSTANTS.interceptActionTypes.CLOSE_MODAL },
  //
  // A TRIGGER NEEDS TO BE ADDED WHEN THIS MODAL IS USED
  //
}
