const percentage = (gifts) =>
  Number.parseFloat(
    gifts
      ?.reduce(
        (total, current) =>
          total +
          (current.isPercent && current.amount
            ? Number.parseFloat(current.amount)
            : 0),
        0,
      )
      .toFixed(2),
  )

const dollar = (gifts) =>
  Number.parseFloat(
    gifts
      ?.reduce(
        (total, current) =>
          total +
          (!current.isPercent && current.amount
            ? Number.parseFloat(current.amount)
            : 0),
        0,
      )
      .toFixed(2),
  )

export default {
  percentage,
  dollar,
}
