import _has from 'lodash/has'

import triggeredFields from './triggeredFields'

export default ({
  data,
  abTest,
  referrer,
  payments,
  isPartner,
  documents,
  answerStore,
  accountCreated,
  charityPartner,
}) => {
  /*
   * In many cases below we prepoluate the answerStore in order to pre-trigger fields
   * that we can safely assume will be triggered based on earlier answered fields.
   * For example, partnerFirstName is required to triggered a bunch of questions and
   * fields that use partnerFirstName in the label. If we know that the user has
   * a partner, we can prepopulate the answerStore with a temporary partnerFirstName
   * value. This function never actually updates the answerStore, so it's safe to
   * prepopulate the answerStore just for this function's scope.
   */

  const { hasPartner, hasChildren, hasPets } = answerStore

  let theAnswerStore = {
    product: 'willPoa',
    ...answerStore,
  }

  if (hasPartner === 'yes') {
    theAnswerStore = {
      partnerFirstName: 'sadf',
      partnerLastName: 'asdf',
      partnerGender: 'other',
      includePartner: 'yes',
      ...theAnswerStore,
    }
  }

  if (hasChildren === 'yes') {
    theAnswerStore = {
      hasStepChildren: 'yes',
      hasMinorChildren: 'yes',
      ...theAnswerStore,
    }
  }

  if (hasPets === 'yes') {
    theAnswerStore = {
      petGuardianName: 'asdf',
      petGuardianRelationship: 'asdasd',
      ...theAnswerStore,
    }
  }

  const theTriggeredFields = triggeredFields({
    abTest,
    referrer,
    payments,
    isPartner,
    documents,
    theData: data,
    charityPartner,
    accountCreated,
    answerStore: theAnswerStore,
    includeOptionalFieldsAndQuestions: false,
  })

  const answeredFields = theTriggeredFields.filter((f) =>
    f.optional ? false : _has(answerStore, f.name),
  )

  return Math.ceil((answeredFields.length / theTriggeredFields.length) * 100)
}
